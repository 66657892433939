/* MathDinosaurGame.css */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Roboto:wght@400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #87CEEB, #E0F7FA);
  overflow: hidden;
}

.math-dinosaur-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2vh;
  height: 100vh;
  box-sizing: border-box;
}

.game-title {
  font-family: 'Fredoka One', cursive;
  color: #4CAF50;
  font-size: 5vh;
  margin-bottom: 2vh;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.problem-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 3vh 4vh;
  margin-bottom: 3vh;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.problem {
  font-size: 4vh;
  color: #4A4A4A;
  font-family: 'Fredoka One', cursive;
  text-align: center;
  letter-spacing: 0.05em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.balloons-container {
  position: relative;
  width: 100%;
  height: 60vh;
  margin-bottom: 2vh;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.balloons-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.2;
  z-index: 1;
}

.balloon {
  position: absolute;
  width: 12%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  animation: float 4s ease-in-out infinite alternate;
  cursor: pointer;
  font-family: 'Fredoka One', cursive;
  font-size: 2.5vh;
  color: #FFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease;
  z-index: 2;
}

.balloon:hover {
  transform: scale(1.1);
}

.balloon::after {
  content: '';
  position: absolute;
  width: 15%;
  height: 20%;
  background: inherit;
  bottom: -10%;
  left: 42.5%;
  transform: rotate(45deg);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(-2deg);
  }

  100% {
    transform: translate(0, 10px) rotate(2deg);
  }
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2vw;
  margin-bottom: 2vh;
}


.treasure-chest {
  width: 8vw;
  height: 8vw;
  background: url('./images/TreasureChest.png') no-repeat center/contain;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  font-size: 1.2vh;
  color: #FFD700;
  text-shadow: 1px 1px 1px #000;
  cursor: pointer;
  transition: transform 0.2s;
  padding-bottom: 1vh;
}

.treasure-chest:hover {
  transform: scale(1.1);
}

.treasure-chest h3 {
  margin: 0;
  padding: 0.3vh 0.6vh;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  font-family: 'Fredoka One', cursive;
  font-size: 1.8vh;
}

.reset-button {
  padding: 1vh 2vw;
  font-size: 2vh;
  font-family: 'Fredoka One', cursive;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.reset-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  /* Increased max-width */
  height: 80vh;
  max-height: 500px;
  /* Increased max-height */
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #4A4A4A;
  font-family: 'Fredoka One', cursive;
  font-size: 2.5em;
  /* Increased font size */
  text-align: center;
}

.modal-content button {
  margin-top: 15px;
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Fredoka One', cursive;
  width: auto;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.modal-content button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.rewards-stickers,
.egg-collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.reward-sticker,
.egg-sticker {
  width: 100px;
  /* Increased size */
  height: 100px;
  /* Increased size */
  object-fit: contain;
  transition: transform 0.2s ease;
}

.reward-sticker:hover,
.egg-sticker:hover {
  transform: scale(1.05);
}

.graduating-dino,
.new-egg {
  position: absolute;
  width: 20%;
  height: 20%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 2s ease-in-out;
  z-index: 10;
}

.graduating-dino {
  animation: graduate 2s ease-in-out forwards;
}

.new-egg {
  animation: newEgg 2s ease-in-out forwards;
}

@keyframes graduate {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    top: 75%;
    left: 75%;
    transform: translate(-50%, -50%) scale(1.2) rotate(180deg);
  }

  100% {
    top: 100%;
    left: 100%;
    transform: translate(-100%, -100%) scale(0.5) rotate(360deg);
  }
}

@keyframes newEgg {
  0% {
    top: 100%;
    left: 0%;
    transform: translate(0%, -100%) scale(0.5);
  }

  50% {
    top: 75%;
    left: 25%;
    transform: translate(-50%, -50%) scale(0.8) rotate(180deg);
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
}